@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  form {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
}
